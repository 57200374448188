.App {
  text-align: center;
}

body{
  background-color: rgb(44,44,44);
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
body {
  margin: 0;
  font-family: Helvetica, Arial, sans-serif;
}
#map {
  width: 100vw;
  height: 100vh;
}

.control-panel {
  position: absolute;
  z-index: 9;
  bottom: 0;
  top: 10;
  right: 10;
  max-width: 400px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.3);
  padding: 12px 24px;
  margin: 20px;
  font-size: 13px;
  line-height: 2;
  color: #6b6b76;
  text-transform: uppercase;
  outline: none;
}

.altitudeButton {
  position: absolute !important;
  bottom: 10px;
  right: 80px;
  z-index: 9;
}

.uploadButton {
  position: absolute !important;
  bottom: 10px;
  right: 10px;
  z-index: 9;
}

.playBtn {
  position: absolute !important;
  bottom: 10px;
  left: 350px;
  z-index: 9;
}

.stopBtn {
  position: absolute !important;
  bottom: 10px;
  left: 420px;
  z-index: 9;
}

.playerProgress {
  position: absolute !important;
  bottom: 30px;
  left: 500px;
  z-index: 9;
}

label {
  display: inline-block;
  width: 100px;
}

.trajinput {
  margin-left: 40px;
}

.gtrackinput {
  margin-left: 15px;
}

.flightName {
  font-size: 19px;
  margin-top: 2px;
  margin-bottom: 0px;
}

.aircraftType{
  font-size: 12px;
  margin-top: 0;
  margin-bottom: 4px;
  font-weight: 400;
}